.label-field-box {
  text-align: center;
  height: 32px;
  padding: 4px;
  position: relative;

  p {
    margin: 0;
  }

  .label-field-text {
    overflow: auto;
    font-size: 18px;
    line-height: 22px;
  }

  .label-field-clarification {
    color: inherit;
    opacity: 0.7;
    font-size: 14px;
    line-height: 9px;
  }
}
