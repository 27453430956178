.list-container {
  padding-left: 20px;

  .item-list-box {
    .item-list {
      display: flex;
      align-items: center;

      > div {
        padding: 0 5px;
        text-align: center;
      }
    }
    .item-list-description {
      font-size: 13px;
      padding-left: 40px;
    }
  }
}
