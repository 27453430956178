.MuiChip-root.color-red {
  background-color: #d32f2f;
  color: white;
}

.MuiChip-root.color-green {
  background-color: #388e3c;
  color: white;
}

.MuiChip-root.color-orange {
  background-color: #f57c00;
  color: white;
}

.MuiChip-root.color-blue {
  background-color: #1976d2;
  color: white;
}

.MuiChip-icon {
  color: inherit !important;
}
