.loader-point-left-to-right, .loader-point-right-to-left {
  border-radius: 2px;
  height: 2px;
}


.loader-point-left-to-right {
  animation-duration: 3s;
  animation-name: slide-left-to-right;
}

@keyframes slide-left-to-right {
  from {
    margin-left: 60px;
    width: 10%;
  }

  to {
    margin-left: 90%;
    width: 10%;
  }
}


.loader-point-right-to-left {
  animation-duration: 3s;
  animation-name: slide-right-to-left;
}

@keyframes slide-right-to-left {
  from {
    margin-left: 90%;
    width: 10%;
  }

  to {
    margin-left: 60px;
    width: 10%;
  }
}
