.add-problem-page {
  width: 100%;
  display: inline-flex;

  .edit-side {
    width: 50%;
    padding: 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    margin-right: 5px;
  }

  .options {
    //position: absolute;
    //left: calc(50% + 15px);
    //top: 65px;
    width: 20px;
    position: relative;

    .options-div {
      position: absolute;
      left: -5px;
      top: calc(50% - 45px);
    }

    .left-icon, .right-icon {
      border-radius: 50%;
      box-shadow: 0 0 4px gray;
      //height: calc(50% - 15px);
      //bottom: 0;
      //position: absolute;
    }

    //
    //.right-icon {
    //  height: calc(50% + 15px);
    //  bottom: 0;
    //  position: absolute;
    //}
  }
}



