.list-test-cases {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .list-test-cases-title {
    width: 90px;
  }
}

.list-test-cases > * {
  margin: 0 20px !important;
}
