.box-name-default {
  display: flex;
}

.box-name-default > :first-child {
  padding: 3px 2px 3px 0;
}

.box-name-default > div {
  padding: 3px 2px 3px 2px;
  margin: auto !important;
}
.box-name-default > svg {
  margin: auto 0 auto 0 !important;
}

.box-name-default > :last-child {
  padding: 3px 0 3px 2px;
}
