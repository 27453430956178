.preview-problem {
  width: calc(100% - 20px);
  padding: 10px;

  .problem-name {
    text-align: center;
    margin-bottom: -1em;
  }

  .problem-properties {
    text-align: center;
    margin-bottom: calc(-1em + 5px);
  }

  .problem-properties-last{
    text-align: center
  }

  .problem-samples {
    width: 100%;

    .problem-samples-head {
      width: 100%;
      display: inline-flex;
    }

    .problem-samples-box {
      display: inline-flex;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.14);

      .problem-samples-box-text {
        width: 50%;
        padding: 0 10px;
        overflow-x: auto;

        span {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}



