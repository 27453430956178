.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.label-svg {
  display: flex;
  z-index: 48;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.label-svg > div {
  flex: 1;
  text-align: center;
  height: 30px;
}
