.title-box {
  margin-bottom: 10px;
  height: 100%;
  width: 100%;
  .title-box-title > * {
    background: inherit;
    color: inherit;
    padding: 5px 10px;
  }
}
