body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

html, #root {
  height: 100%;
}


/*Material styles*/

.MuiListItem-gutters {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.MuiCollapse-container {
  margin: 0;
  padding: 0;
}

.MuiTableCell-body, .MuiTableCell-head {
  color: inherit !important;
  /*font-weight: bold !important;*/
}

.without-border-bottom .MuiTableCell-root {
  border-bottom: none !important;
}

/*My SelectorEditable*/
.MuiOutlinedInput-inputSelect {
  padding-right: 30px !important;
}

/*TextArea Multiline*/
.MuiOutlinedInput-multiline {
  padding: 0 !important;
}

/*MuiFormLabel-root*/
/*MuiInputLabel-root*/
/*MuiInputLabel-formControl*/
/*MuiInputLabel-animated*/
/*MuiInputLabel-shrink*/
/*MuiInputLabel-outlined*/
/*MuiFormLabel-filled*/


/*
TEXT INPUT:
Void text position should be centered
MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined

With text position should left-up
MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled

Focused Text position should left-up


SELECTOR INPUT LABEL:
Always the position should left-up
MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled

*/
.MuiTextField-root >.MuiInputLabel-outlined{
  transform: translate(10px, 18px) scale(1) !important;
}

.MuiTextField-root > .MuiInputLabel-outlined.MuiFormLabel-filled, .MuiTextField-root> .MuiInputLabel-outlined.Mui-focused,
.MuiFormControl-root > .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled{
  transform: translate(15px, -7px) scale(0.75) !important;
}


.MuiLinearProgress-root.color-green .MuiLinearProgress-bar {
  /*background-color: #388e3c;*/
  background-color: #4caf50;
}

.MuiLinearProgress-root.color-red {
  /*background-color: #e57373;*/
}

.MuiLinearProgress-root.color-red .MuiLinearProgress-bar {
  /*background-color: #d32f2f;*/
  background-color: #f44336;
}

.MuiButton-root.Mui-disabled {
  color: inherit !important;
  opacity: 0.5;
}

/*loader-fake*/
.loader-fake {
  height: 100%;
}

.loader-fake .to-bottom {
  margin-top: calc(100vh - 20px);
}

.loader-fake .MuiLinearProgress-root {
  height: 10px;
}

.googleLogoutButton div {
  background-color: transparent !important;
}

.googleLogoutButton span {
  width: 100%;
}
